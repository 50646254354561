function changeKindSelect() {
  setDisabled();
  $('.form-absence-kind').click(function () {
    setDisabled();
  });
}

function setDisabled() {
  $(".form-absence-kind:checked").each(function () {
    switch ($(this).val()) {
      case 'absence':
        $('.nullable-absence').hide();
        toggleTime(false, false);
        toggleReason(true, false, false);
        break;
      case 'late':
        $('.nullable-absence').show();
        toggleTime(true, false);
        toggleReason(false, true, false);
        break;
      case 'leave_early':
        $('.nullable-absence').show();
        toggleTime(false, true);
        toggleReason(false, false, true);
        break;
      case 'late_and_leave_early':
        $('.nullable-absence').show();
        toggleTime(true, true);
        toggleReason(false, true, true);
        break;
    }
  });
}

function changeReasonCategory() {
  ['reason', 'late_reason', 'leave_early_reason'].forEach(function(reason){
    let category = $(`#absence_${reason}_category`);
    let subCategory =  $(`#absence_${reason}_sub_category`);

    if (category.val() !== 'sick') {
      subCategory.hide().prop('disabled', true);
    }
    category.on('change', function () {
      if ($(this).val() === 'sick') {
        subCategory.show().prop('disabled', false);
      } else {
        subCategory.hide().prop('disabled', true);
      }
    });
  });
}

function toggleTime(attendanceState, dismissalState) {
  $('.form-attendance-at').prop('disabled', !attendanceState);
  $('.form-dismissal-at').prop('disabled', !dismissalState);
}

function toggleReason(reasonState, lateReasonState, leaveEarlyReasonState) {
  $(".form-reason").attr("hidden", !reasonState).attr("disabled", !reasonState);
  $(".form-late-reason").attr("hidden", !lateReasonState).attr("disabled", !lateReasonState);
  $(".form-leave-early-reason").attr("hidden", !leaveEarlyReasonState).attr("disabled", !leaveEarlyReasonState);
}

window.changeKindSelect = changeKindSelect;
window.changeReasonCategory = changeReasonCategory;
